*{
    -ms-overflow-style: none;
}
::-webkit-scrollbar {
    display: none;
}

.white-content {
    background: #fff;
    color: black;
}

.main {
    position: relative;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: auto;
    max-width: 650px;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    padding-bottom: 45px;
}

.title-dark {
    max-width: 350px;
}

.title-dark h1 {
    margin: 0;
    font-weight: 400;
}

.title-dark h2 {
    color: gray;
    font-weight: 300;
    margin: 0 0 0.5em 0;
}

.title-dark h3 {
    color: #cccccc;
    font-weight: 300;
    margin: 0 0 0.5em 0;
}

.container-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.container-image {
    position: relative;
    width: 300px;
    height: 300px;
    margin: 0 0 10px 0;
    border-radius: 50%;
    overflow: hidden;
}

.image {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 100%;
}

.description {
    align-content: center;
    line-height: 24px;
    display: block;
    text-align: justify;
    margin-left: 15px;
    margin-right: 15px;
    padding-bottom: 15px;
}

.links {
    margin: 25px 0;
    color: white;
    text-align: center;
}


.links a {
    color: white;
    cursor: pointer;
    text-decoration: underline;
}

@media all and (min-width: 650px) {
    br {
        display: none;
    }
    .links a:not(:last-child) {
        padding: 0 57px 0 0;
    }
    .main {
        margin-top: 50px;
    }
}

@media all and (max-width: 492px) {
    .main {
        font-size: 15px;
    }
    .footer {
        font-size: 15px;
    }
}

@media all and (max-width: 467px) {
    .main {
        font-size: 14px;
    }
    .footer {
        font-size: 14px;
    }
}

@media all and (max-width: 441px) {
    .main {
        font-size: 13px;
    }
    .footer {
        font-size: 13px;
    }
}

@media all and (max-width: 416px) {
    .main {
        font-size: 12px;
    }
    .footer {
        font-size: 12px;
    }
}

@media all and (max-width: 391px) {
    .main {
        font-size: 11px;
    }
    .footer {
        font-size: 11px;
    }
}

@media all and (max-width: 366px) {
    .main {
        font-size: 10px;
    }
    .footer {
        font-size: 10px;
    }
}

@media all and (max-width: 650px) {
    .links a:not(:last-child):not(:nth-child(3)) {
        padding: 0 40px 0 0;
    }
}

.changelogs {
    margin-left: 15px;
    margin-right: 15px;
}

.changelogs-grid {

}

.changelogs-title {
    padding-bottom: 20px;
    padding-top: 10px;
}

.changelogs-text {
    padding-bottom: 25px;
}

.changelogs span {
    cursor: pointer;
}

.changelogs li {
    padding-bottom: 20px;
}

.changelist-div {
    margin: 0;
    display: flex;
    align-items: center;
}

.changelist-date {
    margin-right: 70px;
    width: 150px;
    color: grey
}

.changelist-div p {
    margin-right: 0;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 30px;
        text-align: center;
    padding-bottom: 30px;
}